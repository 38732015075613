.translation-pop-container {
  padding: 5px;
}

.lang-acronym {
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 5px;
  padding-right: 5px;
  border-right: solid 1px;
}

.lang-name {
  text-transform: uppercase;
  font-weight: bold;
}

.translation-options {
  margin: 10px;
  margin-top: 30px;

  .trans-option {
    display: flex;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    width: fit-content;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      text-decoration: underline;
    }

    span {
      margin-left: 5px;
    }
  }
}