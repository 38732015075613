.sidebar-drawer {
  margin-top: 65px !important;
}

.sidebar-menu-icons-container {
  svg {
    margin-left: 10px;
  }
}

.Select-menu-outer {
  z-index: 999999 !important;
}